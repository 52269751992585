<template>
    <div v-if="searchable" class=" p-2 bg-light-subtle border-bottom tree-search">
        <SearchInput class="form-control-sm " @onSearch="search" />
    </div>
    <div class="row overflow" v-bind="$attrs">

        <div v-if="treeObject.loading" class="d-flex justify-content-center">
            <div class="spinner-border text-center my-2" role="status">
                <span class="visually-hidden">{{$t('Loading...')}}</span>
            </div>
        </div>
        
        <TreeList  v-if="treeObject.node.children.length > 0 && !treeObject.loading" :node ="treeObject.node" v-slot={item} >
            <slot :item="item"></slot>
        </TreeList>
        
        <div class="my-2" v-if="treeObject.node.children.length === 0 && !treeObject.loading">{{$t('No data to show')}}</div>
    </div>
</template>

<script setup lang="ts">
import TreeList from 'o365.vue.components.TreeList.vue';
import getTreeObject from 'o365.modules.TreeObject.ts';
import { computed, watch } from 'vue';
import useAsyncComponent from 'o365.vue.composables.AsyncComponent.ts';


const SearchInput = useAsyncComponent('o365.vue.components.SearchInput.vue')

const props = defineProps<{
    options: any,
    whereClause?: string,
}>();
const emit = defineEmits<{
    (e: 'nodeClick', node: any): void,
    (e: 'nodeExpand', node: any): void
}>();

if (props.whereClause) {
    props.options.whereClause = props.whereClause;
    watch(props, () => {
        treeObject.reloadWhereClause(props.whereClause);
    })
}

let treeObject = getTreeObject(props.options);

const searchable = computed(() => {
    return treeObject.configs?.[0]?.searchField && treeObject.configs?.[0]?.keyPathField;
});

if (props.whereClause) {
    treeObject.whereClause = props.whereClause;
}

if (treeObject.node.children.length === 0) {
    treeObject.loading = true;
    treeObject.node.initChildren({
        filterString: props.options.initFilterString
    }).then(() => {
        treeObject.loading = false;
    });
}

treeObject.nodeClick = (node) => {
    emit('nodeClick', node);
}
treeObject.nodeExpand = (node) => {
    emit('nodeExpand', node);
}

function search(pValue: string) {
    //  treeControl.treeObject.resetNode();
    treeObject.doSearch(pValue);
}

defineExpose({treeObject});

</script>
<style scoped>
    .overflow{
        overflow-y:auto;   overflow-x: hidden;
    }
    .tree-search {
        min-height: unset;
    }
</style>